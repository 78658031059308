import React from "react"
import { Link } from "gatsby"

const Posts = ({ posts }) => {
  const [postData, setPostData] = React.useState([])

  React.useEffect(() => {
    const renderedPosts = posts.filter((item, i) => i !== 0 && item)
    setPostData(renderedPosts)
  }, [posts])

  return (
    <div className="row mb40">
      {postData.length &&
        postData.map(({ node }) => (
          <div key={node.title} className="col-sm-4 post-snippet masonry-item">
            {node.iframe ? (
              <div
                className="embed-video-container embed-responsive embed-responsive-16by9 mb0"
                dangerouslySetInnerHTML={{ __html: node.iframe }}
              />
            ) : (
              node.thumbnail && (
                <Link to={node.slug}>
                  <img alt={node.title} src={node.thumbnail.file.url} />
                </Link>
              )
            )}

            <div className="inner">
              <Link to={node.slug}>
                <h5 className="mb0">{node.title}</h5>
                <span className="inline-block mb16">{node.publishedDate}</span>
              </Link>
              <hr />
              {/* <p>{node.snippet}</p>
            <Link className="btn btn-sm" to="/">
              Read More
            </Link> */}
            </div>
          </div>
        ))}
    </div>
  )
}

export default Posts
